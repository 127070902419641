@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&family=Roboto:wght@400;500&display=swap');

/* CSS Variables */
:root {
	/* Layout */
	--sidebar-width: 240px;
	--content-max-width: 1400px;
	--content-padding: 20px;

	/* Colors */
	--color-text: #333;
	--color-text-light: #888;
	--color-background: #fff;
	--bg-color-rgb: 255, 255, 255; /* RGB values for background color */
	--color-primary: #0070f3;
	--color-primary-hover: #0050a3;
	--color-sidebar-bg: #f8f8f8;
	--color-border: #f0f0f0;
	--color-border-light: #e0e0e0;
	--color-code-bg: #f5f5f5;
	--color-tag-bg: #f0f0f0;
	--color-tag-text: #555;
	--color-button-bg: #f4f4f4;
	--color-button-hover: #e8e8e8;
	--color-block-quote-bg: #f9f9f9;

	/* Additional Homepage Variables */
	--card-bg: #ffffff;
	--card-border: rgba(0, 0, 0, 0.05);
	--text-secondary: #555555;
	--tag-bg: #f0f0f0;
	--hero-bg: linear-gradient(to bottom right, rgba(245, 247, 250, 0.8), rgba(245, 247, 250, 0.4));
	--accent-color: #0070f3;
	--accent-color-secondary: #0090ff;
	--accent-color-rgb: 0, 112, 243; /* RGB values for accent color */
	--accent-color-dark: #0050a3;
	--accent-color-light: #e6f0ff;
	--text-color: #333;
	--text-muted: #888;
	--heading-color: #222;
	--border-color: #eaeaea;
	--bg-color-translucent: rgba(255, 255, 255, 0.8);
	--search-bg: #f5f5f5;
	--font-heading: 'Roboto Mono', 'SF Mono', 'Consolas', monospace;
	--font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	--button-bg: #f0f0f0;
	--button-hover-bg: #e0e0e0;

	/* Typography */
	--font-size-base: 16px;
	--font-size-small: 14px;
	--font-size-smaller: 13px;
	--font-size-smallest: 12px;
	--line-height-base: 1.5;
	--line-height-content: 1.6;

	/* Shadows */
	--shadow-small: 0 2px 5px rgba(0, 0, 0, 0.1);
	--shadow-medium: 0 3px 12px rgba(0, 0, 0, 0.1);

	/* Radius */
	--radius-small: 3px;
	--radius-medium: 6px;
	--radius-large: 8px;
	--radius-full: 50%;

	/* Spacing */
	--spacing-xs: 5px;
	--spacing-sm: 10px;
	--spacing-md: 15px;
	--spacing-lg: 20px;
	--spacing-xl: 30px;
	--spacing-2xl: 40px;

	/* Transitions */
	--transition-fast: 0.2s ease;
	--transition-medium: 0.3s ease;

	/* iOS 100vh fix using CSS variables */
	--vh: 1vh;
}

/* Dark mode variables */
.dark-mode {
	--color-text: #e0e0e0;
	--color-text-light: #aaa;
	--color-background: #121212;
	--bg-color-rgb: 18, 18, 18; /* RGB values for dark mode background */
	--color-primary: #5e9eff;
	--color-primary-hover: #7eaeff;
	--color-sidebar-bg: #1e1e1e;
	--color-border: #333;
	--color-border-light: #444;
	--color-code-bg: #2d2d2d;
	--color-tag-bg: #333;
	--color-tag-text: #ccc;
	--color-button-bg: #333;
	--color-button-hover: #444;
	--color-block-quote-bg: #1e1e1e;

	/* Additional Dark Mode Homepage Variables */
	--card-bg: #1e1e1e;
	--card-border: rgba(255, 255, 255, 0.08);
	--text-secondary: #aaaaaa;
	--tag-bg: #2a2a2a;
	--hero-bg: linear-gradient(to bottom right, rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.4));
	--accent-color-dark: #2a75cc;
	--accent-color-rgb: 94, 158, 255; /* RGB values for dark mode accent color */
	--accent-color-light: rgba(94, 158, 255, 0.15);
	--text-color: #e0e0e0;
	--text-muted: #aaa;
	--heading-color: #e0e0e0;
	--border-color: #333;
	--bg-color-translucent: rgba(18, 18, 18, 0.8);
	--search-bg: #1e1e1e;
	--button-bg: #2a2a2a;
	--button-hover-bg: #333;
}

/* Base Styles */
body {
	font-family: var(--font-body);
	line-height: var(--line-height-base);
	color: var(--color-text);
	background-color: var(--color-background);
	padding: 0;
	margin: 0;
	padding-top: 10px; /* Reduced further from 20px */
}

/* Reset margins and padding */
h1, h2, h3, h4, h5, h6, p, ul, ol, figure, blockquote {
	margin: 0;
	padding: 0;
}

/* Base link styles */
a {
	text-decoration: none;
	color: var(--color-primary);
	transition: color var(--transition-fast);
}

a:hover {
	color: var(--color-primary-hover);
}

/* Typography defaults */
code, pre, kbd {
	font-family: 'Roboto Mono', 'SF Mono', 'Menlo', 'Consolas', monospace;
}

/* Apply fallback fonts */
.monospace-text {
	font-family: 'Roboto Mono', 'SF Mono', 'Consolas', monospace;
}

/* Images default */
img {
	max-width: 100%;
	height: auto;
}

/* Box sizing */
*, *::before, *::after {
	box-sizing: border-box;
}

/* Publications Page */
.publications-page {
  padding: 2rem 0;
}

.component-box {
  background-color: var(--color-card-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-sm);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.component-header {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 1rem;
}

.component-header h2 {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: var(--color-text-muted);
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.filter-button {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  color: var(--color-text);
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  transition: all var(--transition-fast);
}

.filter-button:hover {
  background-color: var(--color-primary-background);
}

.filter-button.active {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

/* Publication Card */
.publication-card {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-xs);
  margin-bottom: 1.5rem;
  overflow: hidden;
  transition: transform var(--transition-medium), box-shadow var(--transition-medium);
}

.publication-card:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.publication-featured {
  border-left: 4px solid var(--color-primary);
}

.publication-card-content {
  padding: 1.25rem;
}

.publication-header {
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1rem;
}

.publication-cover {
  flex-shrink: 0;
  width: 100px;
}

.publication-cover img {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  height: auto;
  object-fit: cover;
  width: 100%;
}

.publication-meta {
  flex-grow: 1;
}

.publication-type-and-date {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.publication-type {
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  text-transform: uppercase;
}

.type-badge-book {
  background-color: #e3f8ff;
  color: #0079a1;
}

.type-badge-journal {
  background-color: #e7f8e1;
  color: #2a7b19;
}

.type-badge-conference {
  background-color: #f9e9f6;
  color: #8e3b84;
}

.type-badge-workshop {
  background-color: #fff2e4;
  color: #b45a0b;
}

.type-badge-report {
  background-color: #edf1fa;
  color: #3850a0;
}

.type-badge-other {
  background-color: #f0f0f0;
  color: #505050;
}

.dark-mode .type-badge-book {
  background-color: #054257;
  color: #a8e3ff;
}

.dark-mode .type-badge-journal {
  background-color: #1a4c13;
  color: #c0f5b6;
}

.dark-mode .type-badge-conference {
  background-color: #592053;
  color: #f4c3ee;
}

.dark-mode .type-badge-workshop {
  background-color: #613610;
  color: #ffd6ab;
}

.dark-mode .type-badge-report {
  background-color: #1f2c5a;
  color: #bbc7f7;
}

.dark-mode .type-badge-other {
  background-color: #383838;
  color: #e0e0e0;
}

.publication-date, .publication-citations {
  color: var(--color-text-muted);
  font-size: 0.875rem;
}

.publication-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.publication-authors {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.publication-venue {
  color: var(--color-text-muted);
  font-size: 0.9rem;
  font-style: italic;
}

.publication-body {
  margin-bottom: 1rem;
}

.publication-abstract {
  font-size: 0.95rem;
  line-height: 1.6;
}

.abstract-toggle {
  background: none;
  border: none;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0;
}

.publication-footer {
  border-top: 1px solid var(--color-border-light);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
}

.publication-topics {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.publication-topic-tag {
  background-color: var(--color-background-light);
  border-radius: var(--border-radius-sm);
  color: var(--color-text-muted);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.publication-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.publication-action-button {
  align-items: center;
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  gap: 0.35rem;
  padding: 0.4rem 0.75rem;
  transition: all var(--transition-fast);
}

.publication-action-button:hover {
  background-color: var(--color-background-light);
}

.pdf-button {
  color: #e94640;
}

.doi-button {
  color: #0076d6;
}

.publication-citation {
  background-color: var(--color-background-light);
  border-radius: var(--border-radius-sm);
  margin-top: 1rem;
  padding: 1rem;
}

.publication-citation h4 {
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}

.bibtex-code {
  background-color: var(--color-background);
  border-radius: var(--border-radius-sm);
  font-family: 'Roboto Mono', monospace;
  font-size: 0.85rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding: 0.75rem;
  white-space: pre-wrap;
}

.copy-button {
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius-sm);
  color: white;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.5rem 0.75rem;
  transition: background-color var(--transition-fast);
}

.copy-button:hover {
  background-color: var(--color-primary-hover);
}

.no-results {
  color: var(--color-text-muted);
  font-style: italic;
  padding: 2rem 0;
  text-align: center;
}

/* Navbar styles are defined just once - replacing previous definition */
.main-nav {
  height: 64px;
  position: relative;
  top: 10px; /* Reduced further from 20px */
  z-index: 995;
  border-bottom: 1px solid var(--border-color);
  background-color: rgba(var(--bg-color-rgb), 0.9);
  backdrop-filter: blur(10px);
  margin-bottom: 15px; /* Reduced further from 20px */
}

.nav-container {
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  max-width: var(--content-max-width);
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.nav-brand {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 40px;
}

.nav-items {
  display: flex;
  height: 100%;
}

.nav-search {
  width: 220px;
  justify-self: end;
  margin-left: 20px;
  position: relative;
  top: 0;
}

.search-input {
  height: 36px;
  padding: 0 40px 0 15px;
  border-radius: 18px;
  background-color: var(--search-bg);
  border: none;
  width: 100%;
  color: var(--color-text);
  font-size: 14px;
}

.search-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.nav-item {
  height: 100%;
  position: relative;
  margin: 0 8px;
}

.nav-item a {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: var(--color-text);
  text-decoration: none;
  white-space: nowrap;
}

.nav-item a:hover {
  color: var(--color-primary);
}

.nav-highlight {
  position: absolute !important;
  bottom: -1px !important;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.nav-highlight.visible {
  opacity: 1;
}

/* Fix the blue line when scrolling */
.nav-scrolled .nav-item {
  position: relative;
}

.nav-scrolled .nav-highlight {
  bottom: 0 !important; /* Force bottom position */
  position: sticky !important; /* Enforce absolute positioning */
  width: 100%;
}

/* Social links positioning */
.social-links {
  position: fixed;
  top: 10px; /* Move down slightly from 5px */
  right: 40px; /* Move further right from 15px */
  display: flex;
  gap: 12px; /* Make more compact to leave more space */
  z-index: 100;
}

.social-link {
  width: 28px; /* Reduced from 32px */
  height: 28px; /* Reduced from 32px */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-background); /* Use main background color */
  border: 1px solid var(--color-border); /* Add a border */
  transition: all 0.2s ease;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05); /* Softer shadow */
}

.social-link:hover {
  transform: translateY(-2px);
  background-color: var(--color-button-hover);
}

.social-link svg {
  width: 16px;
  height: 16px;
  color: var(--color-text);
}

/* End of navigation alignment */

/* Nav list for sidebar */
.nav-list {
  list-style: none;
  padding: 0;
}

.sidebar .nav-item {
  font-size: 0.9rem;
  padding: 0.35rem 0;
  height: auto;
}

.sidebar .nav-item a {
  color: var(--color-text);
  display: block;
  transition: color var(--transition-fast);
  height: auto;
  padding: 0;
}

.sidebar .nav-item a:hover {
  color: var(--color-primary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .publication-header {
    flex-direction: column;
    gap: 1rem;
  }

  .publication-cover {
    width: 120px;
  }
}

/* About Page Styles */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* About page layout styles */
.about-layout {
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 60px;
  align-items: start;
}

.about-hero-content {
  position: relative;
  z-index: 1;
}

.about-hero h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 10px;
  background: linear-gradient(120deg, var(--accent-color), var(--accent-color-secondary));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.about-hero h2 {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text-secondary);
  margin-bottom: 20px;
}

.about-tagline {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  color: var(--text-color);
}

.about-sidebar {
  position: sticky;
  top: 100px;
}

.about-nav {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
}

.about-nav-item {
  text-align: left;
  padding: 10px 15px;
  border-radius: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--text-color);
  transition: all 0.2s ease;
}

.about-nav-item:hover {
  background-color: rgba(var(--accent-color-rgb), 0.08);
  color: var(--accent-color);
}

.about-nav-item.active {
  background-color: rgba(var(--accent-color-rgb), 0.12);
  color: var(--accent-color);
  font-weight: 500;
}

.about-contact h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: var(--text-color);
}

.about-social-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-social-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  border-radius: 8px;
  color: var(--text-color);
  transition: all 0.2s ease;
  text-decoration: none;
}

.about-social-link svg {
  width: 18px;
  height: 18px;
}

.about-social-link:hover {
  background-color: rgba(var(--accent-color-rgb), 0.08);
  color: var(--accent-color);
}

.about-content {
  width: 100%;
}

.about-section {
  margin-bottom: 50px;
}

.about-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: var(--heading-color);
  position: relative;
  padding-bottom: 10px;
}

.about-section h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, var(--accent-color), transparent);
  border-radius: 3px;
}

.about-card {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.03);
  border: 1px solid var(--card-border);
}

.about-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 25px;
}

.highlight-item {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.highlight-icon {
  font-size: 1.8rem;
  line-height: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlight-text h4 {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: var(--heading-color);
}

.highlight-text p {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Timeline styling */
.timeline {
  position: relative;
  margin-top: 30px;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  width: 2px;
  background: linear-gradient(to bottom, var(--accent-color-light), transparent);
}

.timeline-item {
  position: relative;
  padding-left: 45px;
  margin-bottom: 40px;
  max-width: 100%;
}

.timeline-marker {
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--accent-color-light);
  border: 2px solid var(--accent-color);
  z-index: 1;
}

.timeline-content {
  position: relative;
}

.job-header {
  margin-bottom: 15px;
}

.job-header h3 {
  font-size: 1.25rem;
  margin-bottom: 6px;
  color: var(--heading-color);
}

.job-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.company {
  font-weight: 500;
}

.job-achievements {
  padding-left: 20px;
  margin-bottom: 15px;
}

.job-achievements li {
  margin-bottom: 10px;
  line-height: 1.6;
  color: var(--text-color);
  max-width: 100%;
  padding-right: 20px;
}

.job-achievements strong {
  color: var(--accent-color);
}

.job-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
}

.tech-tag {
  background-color: rgba(var(--accent-color-rgb), 0.08);
  color: var(--accent-color);
  font-size: 0.75rem;
  padding: 4px 10px;
  border-radius: 30px;
  white-space: nowrap;
}

/* Skills section */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.skill-category h3 {
  font-size: 1.1rem;
  margin-bottom: 12px;
  color: var(--heading-color);
}

.skill-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;
}

.skill-tag {
  background-color: var(--tag-bg);
  color: var(--text-color);
  font-size: 0.85rem;
  padding: 5px 12px;
  border-radius: 6px;
}

/* Publications and talks section */
.publication-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.publication-item {
  display: flex;
  gap: 20px;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.publication-item:hover {
  background-color: rgba(var(--accent-color-rgb), 0.05);
}

.featured-publication {
  border-left: 3px solid var(--accent-color);
  background-color: rgba(var(--accent-color-rgb), 0.05);
}

.publication-year {
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--accent-color);
  min-width: 60px;
}

.publication-details h3 {
  font-size: 1.15rem;
  margin-bottom: 5px;
  color: var(--heading-color);
}

.publication-venue {
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-style: italic;
}

.publication-badges {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.publication-badge {
  background-color: rgba(var(--accent-color-rgb), 0.15);
  color: var(--accent-color);
  font-size: 0.7rem;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 4px;
  text-transform: uppercase;
}

.patents-section {
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
}

.patents-section h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: var(--heading-color);
}

.patent-item {
  margin-bottom: 15px;
}

.patent-item h4 {
  font-size: 1rem;
  margin-bottom: 3px;
  color: var(--heading-color);
}

.patent-id {
  color: var(--text-secondary);
  font-size: 0.85rem;
  font-family: var(--font-heading);
}

/* Education section */
.education-item {
  display: flex;
  flex-direction: column;
}

.degree {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--heading-color);
}

.education-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  color: var(--text-secondary);
  font-size: 0.95rem;
}

/* Responsive styling */
@media (max-width: 900px) {
  .about-layout {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .about-sidebar {
    position: static;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .about-nav {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .about-nav-item {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .about-social-links {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .about-social-link {
    padding: 6px 12px;
  }

  .about-hero h1 {
    font-size: 2.2rem;
  }

  .about-hero h2 {
    font-size: 1.3rem;
  }

  .about-tagline {
    font-size: 1rem;
  }
}

/* Make content areas wider - with stronger specificity */
.content-wrapper > div,
.about-container,
.publications-page,
.component-box {
  max-width: 2000px !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Specifically target the About page content */
.about-content {
  max-width: none !important;
  width: 100% !important;
}

/* Ensure timeline items use full width */
.timeline-content {
  width: 100% !important;
}

/* Make the cards wider */
.about-card,
.publication-card,
.blog-card {
  width: 100% !important;
  max-width: none !important;
  padding: 30px !important;
}

/* Ensure the main content area takes more space */
.main-content {
  width: calc(100% - var(--sidebar-width)) !important;
  max-width: none !important;
  padding-top: 30px !important;
}

.about-layout {
  grid-template-columns: 250px 1fr !important;
}

/* Adjust the layout of About page */
.about-hero {
  padding: 80px 60px !important;
}

/* Publications styling */
.publications-page .component-box {
  max-width: 1600px !important;
  padding: 30px !important;
}

/* Increase text size for better readability */
.job-achievements li {
  font-size: 1.05rem !important;
}
/* Maximize content width */
.blog-container,
.content-wrapper,
.main-content>div,
.post-detail,
.blog-content-container,
.talks-container,
.about-container {
  max-width: none !important;
  width: 95% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/* Ensure nav can still be centered */
.nav-container {
  max-width: var(--content-max-width) !important;
  width: 100% !important;
}

/* Enhanced animations for blog posts - larger */
.blog-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  transform-origin: center !important;
}

.blog-card:hover {
  transform: translateY(-12px) scale(1.03) !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12) !important;
}

/* Improved card animations - bigger movement */
.publication-card:hover,
.about-card:hover {
  transform: translateY(-8px) !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12) !important;
}

/* Add stronger animations to images */
.blog-card img,
.publication-cover img {
  transition: transform 0.5s ease !important;
  transform-origin: center !important;
}

.blog-card:hover img,
.publication-cover:hover img {
  transform: scale(1.08) !important;
}

/* Enhance tag animations - bigger lift */
.publication-topic-tag,
.tech-tag,
.skill-tag {
  transition: all 0.2s ease !important;
}

.publication-topic-tag:hover,
.tech-tag:hover,
.skill-tag:hover {
  transform: translateY(-4px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12) !important;
}

/* Make blog cards larger and more impactful */
.blog-card {
  padding: 0 !important;
  border-radius: 14px !important;
  overflow: hidden !important;
}

.blog-card-content {
  padding: 30px 35px !important;
}

/* Blog grid layout for wider containers - larger cards */
.blog-cards-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr)) !important;
  gap: 40px !important;
}

/* Featured post should be more prominent */
.featured-post {
  grid-column: span 2 !important;
  transform: scale(1.02) !important;
}

/* Add stronger zoom effect on hero section */
.hero-section {
  overflow: hidden !important;
}

.hero-section img {
  transition: transform 0.8s ease !important;
}

.hero-section:hover img {
  transform: scale(1.08) !important;
}

/* Ensure main content is also adjusted */
.content-wrapper {
  margin-top: 10px !important; /* Reduced further from 20px */
}

/* Blog container padding adjustments */
.blog-container {
  padding-top: 10px; /* Reduced further from 20px */
  margin-top: 5px; /* Reduced further from 10px */
}

/* Keep sidebar position fixed */
.sidebar {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 997;
}

/* Archives Page Styles */
.archives-content {
  padding: 10px 0;
}

.archive-year-card {
  margin-bottom: 40px;
}

.year-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.year-header h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--accent-color);
  margin-right: 15px;
  flex-shrink: 0;
}

.year-divider {
  height: 2px;
  background: linear-gradient(90deg, var(--accent-color-light), transparent);
  flex-grow: 1;
  border-radius: 2px;
}

.archive-months-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.archive-month-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.archive-month-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  border-color: var(--accent-color-light);
  background-color: var(--accent-color-light);
  color: var(--accent-color-dark);
}

.month-content {
  display: flex;
  flex-direction: column;
}

.month-name {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.post-count {
  display: flex;
  align-items: baseline;
  color: var(--text-secondary);
}

.count-number {
  font-size: 1.1rem;
  font-weight: 600;
  margin-right: 5px;
  color: var(--accent-color);
}

.count-label {
  font-size: 0.9rem;
}

.month-arrow {
  color: var(--accent-color);
  opacity: 0.7;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.archive-month-card:hover .month-arrow {
  transform: translateX(5px);
  opacity: 1;
}

/* Loading and error states */
.loading-spinner, .error-message, .no-results {
  padding: 40px 20px;
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.error-message {
  color: #e74c3c;
}

/* Search Results Page */
.search-results-page {
  padding: 2rem 0;
  min-height: 50vh;
  margin-bottom: 60px;
}

.search-results-page .page-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.search-input-container {
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
}

.search-input-large {
  width: 100%;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 2rem;
  border: 2px solid var(--border-color);
  background-color: var(--search-bg);
  color: var(--text-color);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-input-large:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.search-input-large::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

/* Autocomplete dropdown styles */
.search-autocomplete {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--border-color);
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  padding: 0.5rem 0;
}

.autocomplete-item {
  display: block;
  padding: 0.75rem 1.25rem;
  transition: background-color 0.2s ease;
  color: var(--text-color);
  text-decoration: none;
  border-left: 3px solid transparent;
}

.autocomplete-item:hover {
  background-color: rgba(var(--accent-color-rgb), 0.08);
  border-left-color: var(--accent-color);
}

.autocomplete-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.autocomplete-date {
  font-style: italic;
}

.autocomplete-reading-time {
  font-weight: 500;
}

/* New autocomplete type indicator */
.autocomplete-type {
  font-size: 0.75rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.1rem;
  font-weight: 600;
}

.autocomplete-view-all {
  display: block;
  text-align: center;
  padding: 0.75rem;
  border-top: 1px solid var(--border-color);
  color: var(--primary-color);
  font-weight: 500;
}

.autocomplete-view-all:hover {
  background-color: rgba(var(--primary-color-rgb), 0.05);
}

/* Search results container */
.search-results-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

/* Search result item */
.search-result-item {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  background: var(--card-bg);
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
}

.search-result-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-color-light);
}

.search-result-link {
  display: block;
  text-decoration: none;
  color: var(--text-color);
}

.search-result-content {
  display: flex;
  padding: 0;
}

.search-result-image {
  flex: 0 0 150px;
  background: var(--bg-secondary);
  position: relative;
  overflow: hidden;
}

.search-result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.search-result-details {
  flex: 1;
  padding: 1.25rem;
}

.search-result-type {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  background: var(--bg-secondary);
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.search-result-title {
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
  font-weight: 600;
  color: var(--heading-color);
}

.search-result-description {
  font-size: 0.95rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  line-height: 1.5;
}

.search-result-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  color: var(--text-tertiary);
}

/* Loading indicator */
.search-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  color: var(--text-secondary);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(var(--primary-color-rgb), 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Search results header */
.search-results-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 1.5rem;
}

.search-results-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Search filter buttons */
.search-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.filter-button {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 0.4rem 0.8rem;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-secondary);
}

.filter-button:hover {
  background: var(--bg-hover);
  border-color: var(--border-color-hover);
}

.filter-button.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

/* No results message */
.no-results {
  text-align: center;
  padding: 3rem 0;
  color: var(--text-secondary);
}

/* Highlighted search terms */
mark {
  background-color: rgba(var(--accent-color-rgb), 0.2);
  padding: 0.1em 0.2em;
  border-radius: 3px;
  color: inherit;
}

/* Responsive styles */
@media (max-width: 768px) {
  .search-result-content {
    flex-direction: column;
  }

  .search-result-image {
    flex: 0 0 150px;
    height: 150px;
    width: 100%;
    position: relative;
  }

  .search-filters {
    overflow-x: auto;
    padding-bottom: 0.5rem;
    flex-wrap: nowrap;
  }

  .filter-button {
    white-space: nowrap;
  }
}

/* Prevent horizontal scrolling and ensure content fits on mobile */
html, body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.blog-container {
  position: relative;
  width: 100%;
}

/* Mobile container for the blog layout will be overridden in mobile.css */
.mobile-container {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

/* Visibility utilities */
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

/* Mobile content overrides to fix thin content */
@media (max-width: 768px) {
  /* Make sure nothing overflows the viewport width */
  body, html, #root {
    width: 100vw !important;
    max-width: 100vw !important;
    overflow-x: hidden !important;
  }

  /* Override ALL width styles on mobile */
  * {
    max-width: 100vw !important;
    overflow-wrap: break-word !important;
  }

  /* Override problematic rules for mobile */
  .content-wrapper > div,
  .about-container,
  .publications-page,
  .component-box,
  .blog-content-container,
  .blog-container,
  .content-wrapper,
  .main-content>div,
  .post-detail,
  .talks-container {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Fix mobile grids */
  .blog-cards-container {
    grid-template-columns: 1fr !important;
    width: 100% !important;
  }

  /* Fix featured posts on mobile */
  .featured-post {
    grid-column: span 1 !important;
  }

  /* Reset all transforms on mobile to prevent layout issues */
  .blog-card:hover,
  .publication-card:hover,
  .about-card:hover,
  .featured-post:hover {
    transform: none !important;
  }
}
