/**
 * Layout Styles
 * Container, sidebar, grid and primary layout components
 */

/* Main Container */
.blog-container {
    display: flex;
    min-height: 100vh;
    position: relative;
    justify-content: center;
}

/* Main Content Area */
.main-content {
    flex: 1;
    padding: var(--content-padding);
    max-width: var(--content-max-width);
    transition: margin-left var(--transition-medium), max-width var(--transition-medium);
    margin-left: 0;
    width: 100%;
    box-sizing: border-box;
}

body.sidebar-open .main-content {
    margin-left: var(--sidebar-width);
    max-width: calc(var(--content-max-width) - var(--sidebar-width));
}

.content-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--spacing-2xl);
    max-width: 820px;
    box-sizing: border-box;
}

/* Sidebar */
.sidebar {
    width: var(--sidebar-width);
    background: var(--color-sidebar-bg);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform var(--transition-medium), width var(--transition-fast);
}

body.sidebar-open .sidebar {
    transform: translateX(0);
}

.sidebar-inner {
    padding: var(--spacing-xl) var(--spacing-lg);
}

/* Sidebar Resize Handle */
.sidebar-resize-handle {
    position: fixed;
    top: 0;
    bottom: 0;
    left: var(--sidebar-width);
    width: 10px;
    cursor: col-resize;
    z-index: 110;
    background: transparent;
}

.sidebar-resize-handle:hover,
.sidebar-resize-handle:active {
    background: rgba(0, 0, 0, 0.05);
}

body.sidebar-open .sidebar-resize-handle {
    display: block;
}

/* Sidebar Toggle Button */
.sidebar-toggle {
    position: fixed;
    top: 50%;
    left: var(--spacing-lg);
    width: 24px;
    height: 40px;
    background: var(--color-sidebar-bg);
    border-radius: 0 3px 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background var(--transition-medium), left var(--transition-medium), opacity var(--transition-medium);
    transform: translateY(-50%);
    border-left: none;
    opacity: 0.8;
}

body.sidebar-open .sidebar-toggle {
    left: var(--sidebar-width);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-toggle:hover {
    background: var(--color-button-hover);
    opacity: 1;
}

.toggle-icon {
    width: 14px;
    height: 10px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    opacity: 0.7;
}

.toggle-icon span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: var(--color-text);
    border-radius: 1px;
    opacity: 0.7;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.toggle-icon span:nth-child(1) {
    top: 0px;
}

.toggle-icon span:nth-child(2), .toggle-icon span:nth-child(3) {
    top: 6px;
}

.toggle-icon span:nth-child(4) {
    top: 12px;
}

body.sidebar-open .toggle-icon span:nth-child(1) {
    top: 6px;
    width: 0%;
    left: 50%;
}

body.sidebar-open .toggle-icon span:nth-child(2) {
    transform: rotate(45deg);
}

body.sidebar-open .toggle-icon span:nth-child(3) {
    transform: rotate(-45deg);
}

body.sidebar-open .toggle-icon span:nth-child(4) {
    top: 6px;
    width: 0%;
    left: 50%;
}

/* Dark Mode Toggle */
.dark-mode-toggle {
    position: fixed;
    bottom: var(--spacing-lg);
    right: var(--spacing-lg);
    width: 40px;
    height: 40px;
    background: var(--color-button-bg);
    border-radius: var(--radius-full);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
    box-shadow: var(--shadow-small);
}

.dark-mode-icon {
    width: 20px;
    height: 20px;
    background: #333;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.dark-mode-icon:after {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    width: 20px;
    height: 20px;
    background: var(--color-button-bg);
    border-radius: 50%;
    transform: translate(50%, -50%);
}

/* Social Links */
.social-links {
    position: absolute;
    top: var(--spacing-lg);
    right: var(--spacing-lg);
    display: flex;
    gap: 15px;
    z-index: 100;
}

.social-link {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-full);
    background: var(--color-button-bg);
    color: var(--color-text);
    text-decoration: none;
    transition: all var(--transition-fast);
    box-shadow: var(--shadow-small);
}

.social-link:hover {
    background: var(--color-button-hover);
    transform: translateY(-2px);
    color: var(--color-primary);
}

.social-link svg {
    width: 16px;
    height: 16px;
}

/* Blog Card Grid Layout */
.blog-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
    margin: 40px 0;
}

/* Hero Section Layout */
.hero-section {
    position: relative;
    margin: 0 auto 80px;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60vh;
    overflow: visible;
    border-radius: 20px;
    background: var(--hero-bg);
}

.hero-content {
    flex: 1;
    padding: 0 40px;
    z-index: 2;
    max-width: 600px;
}

.hero-decoration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 650px;
    animation: float 6s ease-in-out infinite;
    position: relative;
    overflow: visible;
    margin-right: -100px;
}

.hero-graphic {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
}

/* Newsletter Section Layout */
.newsletter-container {
    background: linear-gradient(135deg, var(--accent-color), var(--accent-color-secondary));
    border-radius: 20px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    box-shadow: 0 10px 30px rgba(0, 112, 243, 0.3);
}

.newsletter-form {
    display: flex;
    width: 100%;
    max-width: 500px;
    gap: 10px;
}
