/**
 * Typography Styles
 * Text elements, headings, paragraphs, lists, quotes, and formatting
 */

/* Heading Styles */
.blog-title {
    font-family: var(--font-heading);
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 10px;
}

.page-title {
    font-family: var(--font-heading);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: var(--spacing-2xl);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-border);
}

/* Section Headings */
.section-header {
    margin-bottom: 40px;
    text-align: center;
    position: relative;
}

.section-title {
    font-family: var(--font-heading);
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--heading-color);
}

.section-line {
    height: 4px;
    width: 80px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-color-secondary));
    border-radius: 2px;
    margin: 0 auto;
}

/* Hero Typography */
.hero-title {
    font-size: clamp(2.2rem, 3vw, 3.5rem);
    font-weight: 800;
    line-height: 1.15;
    margin-bottom: 20px;
    color: var(--heading-color);
    font-family: var(--font-heading);
    word-wrap: break-word;
    hyphens: auto;
}

.hero-line {
    display: block;
    background-image: linear-gradient(to right, var(--accent-color), var(--accent-color-secondary));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: fadeInUp 0.8s ease-out;
    animation-fill-mode: both;
    margin-bottom: 5px;
}

.hero-line:nth-child(2) {
    animation-delay: 0.2s;
}

.hero-line:nth-child(3) {
    animation-delay: 0.4s;
}

.hero-subtitle {
    font-size: clamp(0.95rem, 1.5vw, 1.1rem);
    color: var(--text-secondary);
    margin-bottom: 30px;
    animation: fadeInUp 0.8s ease-out 0.4s;
    animation-fill-mode: both;
    line-height: 1.5;
}

/* Content Typography */
.blog-content p {
    font-family: var(--font-body);
    font-size: var(--font-size-base);
    line-height: var(--line-height-content);
    margin-bottom: 24px;
}

.blog-content h2 {
    font-family: var(--font-heading);
    font-size: 20px;
    font-weight: 500;
    margin: var(--spacing-2xl) 0 var(--spacing-lg);
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border);
}

.blog-content h3 {
    font-family: var(--font-heading);
    font-size: 18px;
    font-weight: 500;
    margin: var(--spacing-xl) 0 var(--spacing-md);
}

.blog-content a {
    color: var(--color-primary);
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color var(--transition-fast);
}

.blog-content a:hover {
    border-color: var(--color-primary);
}

/* Blog Meta Data */
.blog-meta {
    font-family: var(--font-heading);
    font-size: var(--font-size-smallest);
    color: var(--color-text-light);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

/* Code Typography */
.blog-content code {
    font-family: 'Roboto Mono', 'Menlo', 'Consolas', monospace;
    background: var(--color-code-bg);
    padding: 2px 4px;
    border-radius: var(--radius-small);
    font-size: var(--font-size-small);
    color: #d33682;
}

/* Lists */
.blog-content ul,
.blog-content ol {
    margin-bottom: 24px;
    padding-left: 28px;
}

.blog-content li {
    margin-bottom: 10px;
}

/* Blockquotes */
.blog-content blockquote {
    margin: var(--spacing-xl) 0;
    padding: 16px 24px;
    border-left: 4px solid var(--color-border-light);
    background: var(--color-block-quote-bg);
    font-style: italic;
    color: var(--color-text);
    border-radius: 0 var(--radius-small) var(--radius-small) 0;
}

.blog-content blockquote p:last-child {
    margin-bottom: 0;
}

/* Horizontal Rule */
.blog-content hr {
    height: 1px;
    background: var(--color-border);
    border: none;
    margin: var(--spacing-2xl) 0;
}

/* Common Text Elements */
.no-posts-message {
    text-align: center;
    padding: 60px 0;
    color: var(--text-muted);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.home-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    color: var(--text-muted);
}

/* Animation Keyframes for Text Effects */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/* Font fallback and better typography classes */
.monospace-text {
    font-family: 'Roboto Mono', 'SF Mono', 'Consolas', monospace;
}

/* Blog Images */
.blog-content img {
  max-width: 100%;
  height: auto;
  border-radius: var(--radius-medium);
  margin: 30px 0;
  box-shadow: var(--shadow-small);
  display: block;
}

.blog-image {
  max-width: 100%;
  height: auto;
  border-radius: var(--radius-medium);
  margin: 30px 0;
  box-shadow: var(--shadow-small);
  display: block;
}

/* For large images that should stand out */
.blog-content img.full-width {
  margin-left: -20px;
  margin-right: -20px;
  max-width: calc(100% + 40px);
  border-radius: var(--radius-small);
}

/* Center images that are smaller than the content width */
.blog-content img.center {
  margin-left: auto;
  margin-right: auto;
}
