/**
 * Component Styles
 * Reusable UI components like cards, buttons, code blocks, etc.
 */

/* Post List Components */
.post-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.post-item {
    margin-bottom: 50px;
    padding-bottom: var(--spacing-2xl);
    border-bottom: 1px solid var(--color-border);
}

.post-item:last-child {
    border-bottom: none;
}

.post-item .post-title {
    font-family: var(--font-heading);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 12px;
}

.post-item .post-title a {
    text-decoration: none;
    color: var(--color-text);
    transition: color var(--transition-fast);
}

.post-item .post-title a:hover {
    color: var(--color-primary);
}

.post-item .post-meta {
    font-family: var(--font-heading);
    font-size: var(--font-size-smallest);
    color: var(--color-text-light);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.post-item .post-tag {
    display: inline-block;
    background: var(--color-tag-bg);
    padding: 2px 6px;
    border-radius: var(--radius-small);
    font-size: var(--font-size-smallest);
    color: var(--color-tag-text);
}

.post-item .post-excerpt {
    font-family: var(--font-body);
    font-size: var(--font-size-base);
    line-height: var(--line-height-content);
    color: var(--color-text-light);
    margin-bottom: 15px;
}

.post-item .post-read-more {
    font-family: var(--font-heading);
    font-size: var(--font-size-small);
}

.post-item .post-read-more a {
    text-decoration: none;
    color: var(--color-primary);
    transition: color var(--transition-fast);
}

.post-item .post-read-more a:hover {
    color: var(--color-primary-hover);
}

/* Sidebar Post Components */
.sidebar .post-item {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-border);
}

.sidebar .post-item:last-child {
    border-bottom: none;
}

.sidebar .post-title {
    font-family: var(--font-body);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 1.5;
}

.sidebar .post-title a {
    text-decoration: none;
    color: var(--color-text);
    transition: color var(--transition-fast);
    font-size: 18px;
}

.sidebar .post-title a:hover {
    color: var(--color-primary);
}

.sidebar .post-meta {
    font-size: var(--font-size-small);
    color: var(--color-text-light);
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.sidebar .post-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.sidebar .post-tag {
    font-size: var(--font-size-smaller);
    background: var(--color-tag-bg);
    color: var(--color-tag-text);
    padding: 4px 10px;
    border-radius: var(--radius-small);
}

/* Blog Post Card Component */
.blog-card {
    position: relative;
    overflow: hidden;
    background: var(--card-bg);
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--card-border);
}

.blog-card-hovered {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08);
}

.blog-card-accent {
    height: 6px;
    width: 100%;
}

.blog-card-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.blog-card-header {
    margin-bottom: 16px;
}

.blog-card-title {
    font-family: var(--font-heading);
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 12px;
    font-weight: 600;
}

.blog-card-title a {
    color: var(--text-color);
    text-decoration: none;
    background-image: linear-gradient(to right, var(--accent-color), var(--accent-color));
    background-repeat: no-repeat;
    background-position: 0 90%;
    background-size: 0% 2px;
    transition: background-size 0.3s ease-in-out;
}

.blog-card-title a:hover {
    background-size: 100% 2px;
}

.blog-card-meta {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: var(--text-muted);
    gap: 15px;
}

.blog-card-date {
    display: flex;
    align-items: center;
}

.blog-card-date::before {
    content: "📅";
    margin-right: 5px;
    font-size: 0.9rem;
}

.blog-card-reading-time {
    display: flex;
    align-items: center;
}

.blog-card-reading-time::before {
    content: "⏱️";
    margin-right: 5px;
    font-size: 0.9rem;
}

.blog-card-excerpt {
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-secondary);
    flex-grow: 1;
}

.blog-card-excerpt p {
    margin: 0;
}

.blog-card-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--card-border);
    padding-top: 16px;
}

.blog-card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.blog-card-tag {
    font-size: 0.8rem;
    padding: 3px 10px;
    border-radius: 25px;
    text-decoration: none;
    color: var(--text-muted);
    background-color: var(--tag-bg);
    transition: all 0.2s ease;
    font-family: var(--font-heading);
}

.blog-card-tag:hover {
    background-color: var(--accent-color-light);
    color: var(--accent-color);
}

.blog-card-action {
    margin-left: auto;
}

.blog-card-read-more {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--accent-color);
    text-decoration: none;
    transition: gap 0.3s ease;
    font-family: var(--font-heading);
}

.blog-card-read-more:hover {
    gap: 10px;
}

.blog-card-read-more svg {
    transition: transform 0.3s ease;
}

.blog-card-read-more:hover svg {
    transform: translateX(3px);
}

/* Featured Post Card Component */
.featured-post-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: var(--card-bg);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--card-border);
}

.featured-post-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-color-secondary));
}

.featured-post-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.featured-post-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.featured-label {
    display: inline-block;
    background: var(--accent-color-light);
    color: var(--accent-color);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.featured-post-title {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 15px;
    font-weight: 600;
}

.featured-post-title a {
    color: var(--heading-color);
    text-decoration: none;
    transition: color 0.2s ease;
}

.featured-post-title a:hover {
    color: var(--accent-color);
}

.featured-post-meta {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: var(--text-muted);
    margin-bottom: 15px;
    gap: 15px;
}

.featured-post-date::before {
    content: "📅";
    margin-right: 5px;
}

.featured-post-reading-time::before {
    content: "⏱️";
    margin-right: 5px;
}

.featured-post-excerpt {
    color: var(--text-secondary);
    margin-bottom: 20px;
    line-height: 1.6;
    flex-grow: 1;
}

.featured-post-link {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 500;
    transition: gap 0.3s ease;
    margin-top: auto;
    font-family: var(--font-heading);
}

.featured-post-link:hover {
    gap: 10px;
}

.featured-post-link svg {
    transition: transform 0.3s ease;
}

.featured-post-link:hover svg {
    transform: translateX(3px);
}

/* Button Components */
.hero-button {
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    gap: 10px;
    margin-right: 16px;
    animation: fadeInUp 0.8s ease-out 0.6s;
    animation-fill-mode: both;
}

.hero-button.primary {
    background: var(--accent-color);
    color: white;
    box-shadow: 0 4px 15px rgba(0, 112, 243, 0.3);
}

.hero-button.primary:hover {
    background: var(--accent-color-dark);
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 112, 243, 0.4);
}

.hero-button.primary svg {
    transition: transform 0.3s ease;
}

.hero-button.primary:hover svg {
    transform: translateX(3px);
}

.hero-button.secondary {
    background: transparent;
    color: var(--accent-color);
    border: 2px solid var(--accent-color-light);
}

.hero-button.secondary:hover {
    background: var(--accent-color-light);
    transform: translateY(-3px);
}

.newsletter-button {
    padding: 15px 25px;
    border-radius: 30px;
    background: white;
    color: var(--accent-color);
    border: none;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.newsletter-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.newsletter-button svg {
    transition: transform 0.3s ease;
}

.newsletter-button:hover svg {
    transform: translateX(3px);
}

/* Loading Component */
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid var(--accent-color-light);
    border-top-color: var(--accent-color);
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

/* Code Block Components */
.code-block {
    margin: var(--spacing-xl) 0;
    border-radius: var(--radius-large);
    overflow: hidden;
    box-shadow: var(--shadow-medium);
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #fafafa;
}

.code-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: #f0f0f0;
    font-family: 'Roboto Mono', 'Menlo', 'Consolas', monospace;
    font-size: var(--font-size-smaller);
    color: var(--color-text);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.code-filename {
    font-weight: 500;
}

.code-actions {
    display: flex;
}

.code-action {
    padding: 4px 8px;
    background: #e0e0e0;
    color: var(--color-text);
    border-radius: var(--radius-small);
    cursor: pointer;
    font-size: var(--font-size-smallest);
    border: none;
    transition: background var(--transition-fast), color var(--transition-fast);
}

.code-action:hover {
    background: #d0d0d0;
}

.code-container {
    overflow-x: auto;
    max-width: 100%;
    background: #fefefe;
}

.code-pre {
    margin: 0;
    padding: 0;
    background: transparent;
    font-family: inherit;
}

.code-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Roboto Mono', 'Menlo', 'Consolas', monospace;
    font-size: var(--font-size-smaller);
    table-layout: fixed;
}

.code-row {
    height: 21px;
}

.code-row:nth-child(odd) {
    background-color: rgba(0,0,0,0.01);
}

.line-number {
    user-select: none;
    text-align: right;
    color: #999;
    background: #f5f5f5;
    padding: 2px 12px;
    width: 40px;
    min-width: 40px;
    border-right: 1px solid #e8e8e8;
    white-space: nowrap;
    vertical-align: top;
    font-variant-numeric: tabular-nums;
}

.code-line {
    padding: 2px 15px;
    white-space: pre;
    color: var(--color-text);
    vertical-align: top;
    line-height: 1.5;
    overflow-wrap: normal;
    word-break: keep-all;
}

/* Tech badges */
.tech-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 30px;
    animation: fadeInUp 0.8s ease-out 0.8s;
    animation-fill-mode: both;
}

.tech-badge {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: var(--card-bg);
    border-radius: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.tech-badge:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.tech-icon {
    font-size: 1.4rem;
}

.tech-name {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text-color);
}

/* Newsletter Components */
.newsletter-content {
    max-width: 600px;
    margin-bottom: 30px;
}

.newsletter-title {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    font-family: var(--font-heading);
}

.newsletter-description {
    font-size: 1.1rem;
    line-height: 1.6;
    opacity: 0.9;
}

.newsletter-input {
    flex-grow: 1;
    padding: 15px 20px;
    border-radius: 30px;
    border: none;
    font-size: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.newsletter-input:focus {
    outline: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

/* Hero Graphic Animations */
.animated-graphic {
    transform-origin: center;
    transform: scale(1.2);
    z-index: 1;
}

.outer-circle {
    animation: rotate 25s linear infinite;
}

.middle-circle {
    animation: pulse 8s ease-in-out infinite;
}

.inner-circle {
    animation: rotate-reverse 15s linear infinite;
}

.center-circle {
    animation: pulse 5s ease-in-out infinite;
}

.vertical-line, .horizontal-line {
    animation: fadeInOut 7s ease-in-out infinite;
}

/* Neural network nodes and connections animations */
.node {
    animation: nodePulse 5s ease-in-out infinite;
}

.input-node-1 {
    animation-delay: 0s;
}

.input-node-2 {
    animation-delay: 0.5s;
}

.input-node-3 {
    animation-delay: 1s;
}

.hidden-node-1 {
    animation-delay: 1.5s;
}

.hidden-node-2 {
    animation-delay: 2s;
}

.hidden-node-3 {
    animation-delay: 2.5s;
}

.output-node-1 {
    animation-delay: 3s;
}

.output-node-2 {
    animation-delay: 3.5s;
}

.connection {
    animation: connectionPulse 8s ease-in-out infinite;
}

/* Stagger connection animations */
.connection:nth-child(odd) {
    animation-delay: 0.3s;
}

.connection:nth-child(3n) {
    animation-delay: 0.6s;
}

.connection:nth-child(4n) {
    animation-delay: 0.9s;
}

/* Signals moving through the network - each following a specific path */
.signal-i1-h1-o1 {
    animation: signalTravel-i1-h1-o1 6s ease-in-out infinite;
}

.signal-i1-h2-o2 {
    animation: signalTravel-i1-h2-o2 6s ease-in-out infinite;
    animation-delay: 1s;
}

.signal-i2-h1-o2 {
    animation: signalTravel-i2-h1-o2 6s ease-in-out infinite;
    animation-delay: 2s;
}

.signal-i2-h3-o1 {
    animation: signalTravel-i2-h3-o1 6s ease-in-out infinite;
    animation-delay: 3s;
}

.signal-i3-h2-o1 {
    animation: signalTravel-i3-h2-o1 6s ease-in-out infinite;
    animation-delay: 4s;
}

.signal-i3-h3-o2 {
    animation: signalTravel-i3-h3-o2 6s ease-in-out infinite;
    animation-delay: 5s;
}

/* Define the specific paths for each signal */
@keyframes signalTravel-i1-h1-o1 {
    0%, 100% {
        cx: 100;
        cy: 150;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 130;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 170;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 170;
        opacity: 0;
        r: 0;
    }
}

@keyframes signalTravel-i1-h2-o2 {
    0%, 100% {
        cx: 100;
        cy: 150;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 200;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 230;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 230;
        opacity: 0;
        r: 0;
    }
}

@keyframes signalTravel-i2-h1-o2 {
    0%, 100% {
        cx: 100;
        cy: 200;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 130;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 230;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 230;
        opacity: 0;
        r: 0;
    }
}

@keyframes signalTravel-i2-h3-o1 {
    0%, 100% {
        cx: 100;
        cy: 200;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 270;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 170;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 170;
        opacity: 0;
        r: 0;
    }
}

@keyframes signalTravel-i3-h2-o1 {
    0%, 100% {
        cx: 100;
        cy: 250;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 200;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 170;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 170;
        opacity: 0;
        r: 0;
    }
}

@keyframes signalTravel-i3-h3-o2 {
    0%, 100% {
        cx: 100;
        cy: 250;
        r: 3;
        opacity: 0;
    }
    10% {
        opacity: 1;
        r: 3;
    }
    40% {
        cx: 200;
        cy: 270;
        r: 3;
        opacity: 1;
    }
    75% {
        cx: 300;
        cy: 230;
        r: 3;
        opacity: 1;
    }
    90% {
        cx: 350;
        cy: 230;
        opacity: 0;
        r: 0;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-reverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
        r: 120;
    }
    50% {
        opacity: 0.8;
        r: 150;
    }
    100% {
        opacity: 0.5;
        r: 120;
    }
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.7;
    }
}

@keyframes nodePulse {
    0%, 100% {
        fill-opacity: 0.2;
        r: 10;
    }
    50% {
        fill-opacity: 0.5;
        r: 12;
    }
}

@keyframes connectionPulse {
    0%, 100% {
        opacity: 0.3;
        stroke-dasharray: 3 3;
    }
    50% {
        opacity: 0.7;
        stroke-dasharray: 4 2;
    }
}

/* Talks Page Styles */
.talks-page {
    padding: 40px 0;
}

.section-subtitle {
    text-align: center;
    color: var(--text-secondary);
    max-width: 700px;
    margin: 20px auto 30px;
    font-size: 1.1rem;
    line-height: 1.6;
}

.talks-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    gap: 20px;
}

.talks-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.filter-button {
    padding: 8px 16px;
    border-radius: 30px;
    background: var(--button-bg);
    color: var(--text-secondary);
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font-heading);
}

.filter-button:hover {
    background: var(--button-hover-bg);
    transform: translateY(-2px);
}

.filter-button.active {
    background: var(--accent-color);
    color: white;
}

/* Talks Component Box */
.talks-component-box {
    background: var(--card-bg);
    border-radius: 12px;
    box-shadow: var(--shadow-small);
    border: 1px solid var(--card-border);
    overflow: hidden;
    margin-bottom: 60px;
}

.talks-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    background-color: rgba(var(--bg-color-rgb), 0.5);
    border-bottom: 1px solid var(--card-border);
}

.talks-component-title {
    font-family: var(--font-heading);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    color: var(--text-color);
}

.talks-component-content {
    padding: 30px;
}

/* View Toggle */
.view-toggle {
    display: flex;
    gap: 5px;
    background: var(--button-bg);
    border-radius: 8px;
    padding: 4px;
}

.view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.2s ease;
}

.view-button:hover {
    color: var(--text-color);
    background: var(--button-hover-bg);
}

.view-button.active {
    background: var(--accent-color);
    color: white;
}

/* Container for both views */
.talks-container {
    position: relative;
}

/* List View (Standard vertical cards) */
.talks-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 auto;
}

/* Grid View (True multi-column grid) */
.talks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin: 0 auto;
}

.talk-card-grid {
    height: auto;
}

.talk-card-grid .talk-card-content {
    padding: 15px;
}

.talk-card-grid .talk-card-title {
    font-size: 1.1rem;
    margin-bottom: 8px;
}

.talk-card-grid .talk-card-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 0.8rem;
}

.talk-card-grid .talk-card-event::after {
    display: none;
}

.talk-card-grid .talk-card-topics {
    margin-top: 10px;
}

.talk-card-grid .talk-topic-tag {
    font-size: 0.75rem;
    padding: 2px 8px;
}

.talk-card-grid .talk-play-button {
    width: 50px;
    height: 50px;
}

.talk-card-grid .talk-play-button svg {
    width: 20px;
    height: 20px;
}

/* Talk Card Component - base styles */
.talk-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    background: var(--card-bg);
    box-shadow: var(--shadow-small);
    transition: all 0.3s ease;
    border: 1px solid var(--card-border);
    height: 100%;
}

.talk-card-hovered {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.talk-card-video {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: #000;
    overflow: hidden;
}

.talk-card-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.talk-thumbnail-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.talk-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.talk-thumbnail-container:hover .talk-thumbnail {
    transform: scale(1.05);
}

.talk-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: white;
}

.talk-play-button svg {
    width: 30px;
    height: 30px;
    margin-left: 5px; /* Adjust positioning of the play triangle */
}

.talk-thumbnail-container:hover .talk-play-button {
    background: rgba(var(--accent-color-rgb), 0.9);
    transform: translate(-50%, -50%) scale(1.1);
}

.talk-card-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.talk-card-header {
    margin-bottom: 15px;
}

.talk-card-title {
    font-family: var(--font-heading);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--heading-color);
}

.talk-card-meta {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 0.9rem;
    color: var(--text-muted);
}

.talk-card-event {
    position: relative;
    padding-right: 16px;
}

.talk-card-event::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background: var(--accent-color);
    border-radius: 50%;
}

.talk-card-description {
    margin-bottom: 20px;
    color: var(--text-secondary);
    line-height: 1.6;
    flex-grow: 1;
}

.talk-card-topics {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 12px;
}

.talk-topic-tag {
    display: inline-block;
    background: var(--tag-bg);
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 0.85rem;
    color: var(--text-secondary);
}

.talk-card-actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
}

.watch-talk-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;
}

.watch-talk-button:hover {
    background-color: var(--accent-hover-color);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.watch-talk-button svg {
    width: 16px;
    height: 16px;
}

/* Grid mode specific adjustments */
.talk-card-grid .talk-card-actions {
    margin-top: 12px;
}

.talk-card-grid .watch-talk-button {
    padding: 6px 12px;
    font-size: 0.8rem;
}

.no-talks-message {
    text-align: center;
    padding: 60px 0;
    color: var(--text-muted);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* Responsive adjustments for talks grid on smaller screens */
@media (max-width: 768px) {
    .talks-grid {
        grid-template-columns: 1fr;
    }

    .talk-card-title {
        font-size: 1.3rem;
    }
}

/* About Page Styling */
.about-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.about-hero {
    margin-bottom: 50px;
    padding: 0;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 112, 243, 0.08);
    border: 1px solid #f0f7ff;
    display: flex;
}

.about-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(230, 240, 255, 0.7) 0%, rgba(255, 255, 255, 0) 50%);
    z-index: 1;
}

.about-hero-content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 100%;
}

.about-hero-text {
    flex: 1;
    padding: 50px 40px;
}

.about-hero-image {
    flex: 0 0 38%;
    height: 100%;
    min-height: 400px;
    position: relative;
    overflow: hidden;
}

.about-hero-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 2;
}

.about-hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    filter: brightness(1.02);
}

.about-hero h1 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 12px;
    color: #111;
    position: relative;
    display: inline-block;
}

.about-hero h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 4px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-color-secondary));
    border-radius: 2px;
}

.about-hero h2 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 24px;
    color: var(--accent-color);
}

.about-tagline {
    font-size: 1.15rem;
    line-height: 1.7;
    color: #555;
    max-width: 100%;
    margin-bottom: 0;
    position: relative;
}

