/**
 * Navigation Styles
 * Navbar, menus, and sidebar navigation components
 */

/* Main Navigation */
.main-nav {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    padding: 0;
    margin-bottom: 40px;
    position: sticky;
    top: 0;
    z-index: 90;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    background-color: rgba(var(--bg-color-rgb), 0.7);
}

.main-nav-item {
    margin: 0 15px;
    font-family: 'Roboto Mono', monospace;
    font-size: var(--font-size-small);
}

.main-nav-item a {
    text-decoration: none;
    color: var(--color-text);
    transition: color var(--transition-fast);
    padding: 5px 0;
}

.main-nav-item a:hover {
    color: var(--color-primary);
}

.main-nav-item.active a {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}

/* Enhanced Navigation Styles */
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--content-max-width);
    padding: 15px 20px;
    background-color: transparent;
}

.nav-scrolled {
    position: sticky;
    top: 0;
    background-color: rgba(var(--bg-color-rgb), 0.85);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    padding: 5px 0;
    transition: all var(--transition-fast);
}

/* Navigation Brand/Logo */
.nav-brand {
    display: flex;
    align-items: center;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-color);
    font-weight: 600;
    gap: 8px;
    transition: transform 0.3s ease;
}

.logo-link:hover {
    transform: translateY(-2px);
}

.logo-symbol {
    font-family: 'Roboto Mono', monospace;
    margin-right: 8px;
    color: var(--color-primary);
}

.logo-text {
    font-family: 'Roboto Mono', monospace;
    font-size: 1.4rem;
    background-image: linear-gradient(120deg, var(--accent-color), var(--accent-color-secondary));
    background-clip: text;
    -webkit-background-clip: text;
}

/* Navigation Items */
.nav-items {
    display: flex;
    gap: 8px;
}

.nav-item {
    position: relative;
    margin: 0 5px;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.95rem;
}

.nav-item a {
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.2s ease;
    padding: 8px 12px;
    display: block;
    position: relative;
    font-weight: 500;
}

.nav-item.active a {
    color: var(--accent-color);
}

.nav-item a:hover {
    color: var(--accent-color);
}

/* Navigation Highlight Animation */
.nav-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-primary);
    transform: scaleX(0);
    transition: transform var(--transition-fast);
    transform-origin: left center;
}

.nav-highlight.visible {
    transform: scaleX(1);
    opacity: 1;
}

/* Navigation Search */
.nav-search {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 20px;
}

.nav-search form {
    width: 100%;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input {
    font-family: var(--font-body);
    font-size: 0.9rem;
    padding: 8px 15px;
    padding-right: 40px; /* Make room for the search icon */
    border: none;
    border-radius: 20px;
    background: var(--search-bg);
    color: var(--text-color);
    width: 200px;
    transition: all 0.3s ease;
}

.search-input:focus {
    width: 260px;
    outline: none;
    box-shadow: 0 0 0 2px var(--accent-color-light);
}

/* Search Button and Autocomplete Styles */
.search-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    color: var(--text-muted);
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.search-icon:hover {
    color: var(--accent-color);
}

/* Autocomplete Dropdown */
.autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--card-bg);
    border-radius: var(--radius-medium);
    margin-top: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    overflow-y: auto;
    z-index: 100;
    padding: 10px 0;
}

.autocomplete-item {
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    text-decoration: none;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--border-color-light);
}

.autocomplete-item:last-child {
    border-bottom: none;
}

.autocomplete-item:hover {
    background-color: var(--hover-bg);
}

.autocomplete-type {
    font-size: 0.7rem;
    color: var(--accent-color);
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.autocomplete-title {
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 5px;
}

.autocomplete-meta {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: var(--text-muted);
}

.autocomplete-date {
    margin-right: 12px;
}

.autocomplete-reading-time {
    display: flex;
    align-items: center;
}

.autocomplete-reading-time::before {
    content: "•";
    margin-right: 5px;
}

.autocomplete-view-all {
    display: block;
    text-align: center;
    padding: 10px;
    font-size: 0.85rem;
    color: var(--accent-color);
    font-weight: 500;
    text-decoration: none;
    margin-top: 5px;
    transition: color 0.2s ease;
}

.autocomplete-view-all:hover {
    color: var(--accent-color-dark);
    text-decoration: underline;
}

/* Sidebar Navigation Components */
.nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 var(--spacing-xl) 0;
}

.sidebar-logo {
    font-family: var(--font-heading);
    font-size: var(--font-size-base);
    color: var(--color-text);
    margin-bottom: var(--spacing-2xl);
    display: block;
}

.sidebar-section {
    margin-bottom: var(--spacing-xl);
}

.sidebar-heading {
    font-family: var(--font-heading);
    font-size: var(--font-size-small);
    font-weight: 500;
    margin-bottom: var(--spacing-md);
    color: var(--color-text);
    border-bottom: 1px solid var(--color-border-light);
    padding-bottom: 8px;
}

/* Table of Contents Navigation */
.toc-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.toc-item {
    font-family: var(--font-body);
    font-size: var(--font-size-smaller);
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 2px solid transparent;
}

.toc-item.active {
    border-left: 2px solid var(--color-primary);
}

.toc-item a {
    text-decoration: none;
    color: var(--color-text);
    display: block;
    transition: color var(--transition-fast);
}

.toc-item a:hover {
    color: var(--color-primary);
}

.toc-item.active a {
    color: var(--color-primary);
}

/* Tag Cloud Navigation */
.tag-cloud {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    background: var(--color-tag-bg);
    padding: 4px 8px;
    border-radius: var(--radius-small);
    font-size: var(--font-size-smallest);
    color: var(--color-tag-text);
    transition: background var(--transition-fast), color var(--transition-fast);
    cursor: pointer;
    text-decoration: none;
}

.tag:hover {
    background: var(--color-button-hover);
}

/* Category Filter Navigation */
.category-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
}

.category-button {
    padding: 8px 16px;
    border-radius: 30px;
    background: var(--button-bg);
    color: var(--text-secondary);
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    font-weight: 500;
}

.category-button:hover {
    background: var(--button-hover-bg);
    transform: translateY(-2px);
}

.category-button.active {
    background: var(--accent-color);
    color: white;
}
