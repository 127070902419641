/**
 * Mobile Styles
 * Styles specific to mobile devices
 */

/* CSS rules can't fully solve all scroll position issues - add the following JavaScript comment for the developer to implement */
/*
Important: Add this JavaScript to reset scroll on page navigation:

document.addEventListener('DOMContentLoaded', function() {
  // Force scroll to top on page load
  window.scrollTo(0, 0);

  // For client-side navigation frameworks
  if (typeof history !== 'undefined' && history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }

  // Reset scroll position when navigating to a new page
  document.querySelectorAll('a[href]').forEach(link => {
    if (!link.href.startsWith('#') && !link.target) {
      link.addEventListener('click', function() {
        // For frameworks that handle client-side navigation
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      });
    }
  });
});
*/

/* Mobile Scroll Position Fix - Enhanced */
@media screen and (max-width: 767px) {
  html.mobile-device, body.mobile {
    scroll-padding-top: 0 !important;
    scroll-margin-top: 0 !important;
    scroll-snap-align: start !important;
    scroll-snap-type: none !important;
    overscroll-behavior-y: none !important;
    height: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    position: static !important;
    touch-action: manipulation !important;
  }

  /* This ensures content starts at the very top */
  .mobile-container {
    scroll-snap-align: start !important;
    scroll-margin-top: 0 !important;
    scroll-padding-top: 0 !important;
  }

  /* Force the page to stay at the top when loading */
  .mobile-container .main-content,
  .mobile-container .content-wrapper {
    scroll-snap-align: start !important;
  }

  /* iOS specific fixes */
  @supports (-webkit-touch-callout: none) {
    /* iOS specific fix */
    html, body {
      /* In iOS, prevent any scroll memory */
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch !important;
      position: static !important;
    }

    /* Force content to start at the top */
    .mobile-container .main-content {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }

    /* Prevent iOS rubber-banding effect from causing scroll position issues */
    .mobile-container {
      overscroll-behavior-y: none !important;
      overflow: auto !important;
      height: auto !important;
      min-height: -webkit-fill-available !important;
    }
  }
}

/* Stronger reset rules for iOS */
html, body {
  /* Added !important to strengthen these rules */
  overflow-x: hidden !important;
  overflow-y: auto !important;
  min-height: 100% !important;
  max-height: none !important;
  position: static !important;
}

/* Override any fixed positioning on body */
body.mobile {
  position: static !important;
  overflow-y: auto !important;
  height: auto !important;
  min-height: 100% !important;
}

/* Fix for page content to always start at the top */
.mobile-container .main-content > *:first-child,
.mobile-container .content-wrapper > *:first-child {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

/* Fix for scroll restoration */
@media screen and (max-width: 767px) {
  html {
    scroll-behavior: auto !important;
    overflow-y: auto !important;
  }

  body {
    overflow-y: auto !important;
    min-height: 100% !important;
  }
}

/* Core Mobile Scrolling Fixes - Simplified to work properly */
body.mobile {
  /* Reset to normal scrolling behavior */
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: static;
  height: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  /* Ensure pages always start at the top */
  scroll-behavior: auto !important;
  scroll-padding-top: 0 !important;
}

/* Force scroll position to top on page load for mobile */
html.mobile-device {
  scroll-behavior: auto !important;
  scroll-padding-top: 0 !important;
}

/* Mobile Container - Make content fully visible */
.mobile-container {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  --mobile-font-scale: 1.05;
  --mobile-line-height: 1.6;
  /* Basic scrolling enhancements without breaking normal behavior */
  -webkit-overflow-scrolling: touch;
  /* Prevent text resizing unexpectedly */
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  /* Reset scroll position on navigation */
  scroll-behavior: auto !important;
  scroll-padding-top: 0 !important;
  /* Set initial view at top */
  scroll-snap-type: y mandatory !important;
}

.mobile-container .main-content > *:first-child {
  scroll-snap-align: start !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

/* JavaScript helper class to reset scroll position */
.scroll-reset {
  scroll-behavior: auto !important;
  scroll-padding-top: 0 !important;
}

/* Add styles to prevent focus from causing scroll jumps */
.mobile-container * {
  scroll-margin-top: 0 !important;
}

/* Ensure inputs don't trigger scroll position changes */
.mobile-container input,
.mobile-container textarea,
.mobile-container select,
.mobile-container button {
  scroll-margin: 0 !important;
}

/* Main content scrolling fixes */
.mobile-container .main-content {
  flex: 1;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 70px !important; /* Space for bottom nav */
  /* Basic touch scrolling without breaking normal behavior */
  -webkit-overflow-scrolling: touch;
}

/* Content wrapper styling */
.mobile-container .content-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  /* Basic touch scrolling */
  -webkit-overflow-scrolling: touch;
}

/* Specific styles for mobile-content-wrapper */
.mobile-content-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 auto !important;
  overflow-x: hidden !important;
  /* Basic touch scrolling without breaking normal behavior */
  -webkit-overflow-scrolling: touch;
}

/* Horizontal scrolling fix only for filter elements that need to scroll */
.mobile-container .talks-filter {
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  padding: 0 15px 10px 15px !important;
  -webkit-overflow-scrolling: touch !important;
  scrollbar-width: none !important; /* Firefox */
  gap: 8px !important;
  width: 100% !important;
  max-width: none !important;
}

/* Reset all problematic transforms globally */
.mobile-container *:not(svg):not(path) {
  transform: none !important;
  will-change: auto !important;
  backface-visibility: visible !important;
  perspective: none !important;
}

/* Reset potentially problematic scroll properties */
.mobile-container {
  scroll-behavior: auto !important;
  overscroll-behavior: auto !important;
  touch-action: auto !important;
}

/* Force all content to be full width */
.mobile-container .content-wrapper > div {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 15px !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
}

/* Force all content containers to be full width */
.mobile-container .post-detail,
.mobile-container .blog-content-container,
.mobile-container .talks-container,
.mobile-container .about-container,
.mobile-container .publications-page,
.mobile-container .search-results-page,
.mobile-container .archives-content,
.mobile-container .about-content,
.mobile-container .timeline-content,
.mobile-container .publication-content {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  box-sizing: border-box !important;
  /* Remove transform that might interfere with scrolling */
  transform: none;
  /* Ensure scroll position reset */
  scroll-snap-align: start;
  scroll-margin-top: 0;
}

/* Mobile dark mode toggle */
.mobile-dark-mode-toggle {
  position: fixed;
  bottom: 80px; /* Position above the bottom nav */
  right: 16px;
  z-index: 1010;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-button-bg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* The inner icon should match the desktop version */
.mobile-dark-mode-toggle .dark-mode-icon {
  width: 20px;
  height: 20px;
  background: #333;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.mobile-dark-mode-toggle .dark-mode-icon:after {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  width: 20px;
  height: 20px;
  background: var(--color-button-bg);
  border-radius: 50%;
  transform: translate(50%, -50%);
}

/* Dark mode specific styles */
.dark-mode .mobile-dark-mode-toggle .dark-mode-icon {
  background: #f1c40f;
}

.dark-mode .mobile-dark-mode-toggle .dark-mode-icon:after {
  background: var(--color-button-bg);
}

/* Mobile Navbar */
.mobile-navbar {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 1000;
  background-color: rgba(var(--bg-color-rgb), 0.95);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* Prevent scroll issue when navigating */
  transform: translateZ(0);
  will-change: transform;
}

.mobile-navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobile-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-symbol {
  font-family: 'Roboto Mono', monospace;
  color: var(--color-primary);
  margin-right: 5px;
}

.mobile-logo-text {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-text);
  background-image: linear-gradient(120deg, var(--accent-color), var(--accent-color-secondary));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.mobile-search-button {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  cursor: pointer;
  padding: 0;
  gap: 4px;
}

.mobile-search-button svg {
  width: 22px;
  height: 22px;
}

.mobile-search-button span {
  font-size: 12px;
  font-weight: 500;
}

/* Mobile Search Panel */
.mobile-search-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  backdrop-filter: none;
  opacity: 1;
  background-image: none;
  /* Use rgba with 1.0 alpha to ensure complete opacity */
  background-color: rgba(var(--bg-color-rgb), 1);
  background-blend-mode: normal;
  /* Additional fixes to prevent any transparency */
  -webkit-backdrop-filter: none;
}

.mobile-search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.mobile-search-input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 15px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--search-bg);
  color: var(--color-text);
  font-size: 16px; /* Larger font size for mobile */
}

.mobile-search-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-muted);
  cursor: pointer;
  padding: 0;
}

/* Mobile Search Results */
.mobile-search-results {
  margin-top: 10px;
  border-top: 1px solid var(--border-color);
  background-color: rgba(var(--bg-color-rgb), 1);
}

.mobile-search-result {
  display: block;
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  color: var(--color-text);
  text-decoration: none;
}

.mobile-result-title {
  font-weight: 500;
  font-size: 0.95rem;
}

.mobile-view-all-results {
  display: block;
  padding: 12px 15px;
  text-align: center;
  color: var(--accent-color);
  font-weight: 500;
  text-decoration: none;
  background-color: rgba(var(--accent-color-rgb), 0.05);
}

/* Bottom Navigation Bar */
.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(var(--bg-color-rgb), 0.95);
  backdrop-filter: blur(10px);
  border-top: 1px solid var(--border-color);
  z-index: 1000;
  padding-bottom: env(safe-area-inset-bottom, 0);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-text-muted);
  font-size: 0.75rem;
  padding: 8px 0;
  width: 20%;
  transition: color 0.2s ease;
}

.bottom-nav-icon {
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.bottom-nav-item.active {
  color: var(--color-primary);
}

/* Small mobile devices */
@media (max-width: 360px) {
  .bottom-nav-item {
    font-size: 0.7rem;
  }

  .bottom-nav-icon {
    font-size: 1rem;
  }

  .mobile-logo-text {
    font-size: 1rem;
  }
}

/* Drastically simplify mobile cards styling to make content visible */
.mobile-container .blog-card,
.mobile-container .publication-card,
.mobile-container .talk-card,
.mobile-container .archive-month-card,
.mobile-container .about-card,
.mobile-container .timeline-item,
.mobile-container .featured-post {
  width: 100% !important;
  margin: 0 0 20px 0 !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Drastically simplify grids to force single column for all content */
.mobile-container .blog-cards-container,
.mobile-container .blog-cards-grid,
.mobile-container .archive-months-grid,
.mobile-container .featured-posts,
.mobile-container .post-grid,
.mobile-container .talks-grid,
.mobile-container .publications-container,
.mobile-container .skills-grid,
.mobile-container .about-highlights {
  display: grid !important;
  grid-template-columns: 1fr !important;
  gap: 20px !important;
  width: 100% !important;
  max-width: 100% !important;
}

/* Create mobile styles for content areas */
.mobile-container .content-section {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  padding: 0 !important;
}

/* Improve spacing for titles and headings on mobile */
.mobile-container h1 {
  font-size: 1.8rem !important;
  line-height: 1.3 !important;
  margin: 1rem 0 !important;
}

.mobile-container h2 {
  font-size: 1.5rem !important;
  line-height: 1.4 !important;
  margin: 1.5rem 0 1rem !important;
}

.mobile-container h3 {
  font-size: 1.3rem !important;
  line-height: 1.4 !important;
  margin: 1.2rem 0 0.8rem !important;
}

.mobile-container h4 {
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
  margin: 1rem 0 0.6rem !important;
}

.mobile-container p,
.mobile-container li,
.mobile-container blockquote {
  font-size: 1rem !important;
  line-height: 1.6 !important;
  margin-bottom: 1rem !important;
}

/* Remove all transforms and fancy animations that could cause mobile issues */
.mobile-container .blog-card:hover,
.mobile-container .publication-card:hover,
.mobile-container .talk-card:hover,
.mobile-container .archive-month-card:hover,
.mobile-container .about-card:hover {
  transform: none !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !important;
}

/* Fix for container elements */
.mobile-container .content-wrapper > div > div,
.mobile-container .main-content > div > div {
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/* Fix talk card images on mobile */
.mobile-container .talk-thumbnail-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.mobile-container .talk-thumbnail {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.mobile-container .talk-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 60px !important;
  height: 60px !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  transition: all 0.2s ease !important;
}

.mobile-container .talk-play-button svg {
  width: 30px !important;
  height: 30px !important;
}

.mobile-container .talk-thumbnail-container:active .talk-play-button {
  background-color: var(--accent-color) !important;
  transform: translate(-50%, -50%) scale(0.95) !important;
}

/* Fix iframe and YouTube videos with ultra-specific rules */
.mobile-container .talk-card-video {
  position: relative !important;
  padding-bottom: 56.25% !important; /* 16:9 Aspect Ratio */
  height: 0 !important;
  overflow: hidden !important;
}

.mobile-container .talk-card-video iframe {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

/* Special rule to make sure the iOS YouTube thumbnails load properly */
.mobile-container img[src*="youtube"] {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  z-index: 1 !important;
}

/* Direct fix for YouTube thumbnails */
.mobile-container img[src*="youtube.com/vi/"] {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 1 !important;
}

/* About page fixes */
.mobile-container .about-container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 10px 15px !important;
  margin: 0 auto !important;
  overflow: visible !important;
}

.mobile-container .about-layout {
  display: block !important;
  width: 100% !important;
  max-width: 100% !important;
}

.mobile-container .about-sidebar {
  display: none !important; /* Hide the sidebar on mobile */
}

.mobile-container .about-content {
  width: 100% !important;
  padding: 0 !important;
}

.mobile-container .about-section {
  display: block !important;
  width: 100% !important;
  margin-bottom: 2rem !important;
  padding: 0 !important;
}

.mobile-container .about-section h2 {
  margin-bottom: 1rem !important;
  font-size: 1.5rem !important;
}

.mobile-container .about-card {
  padding: 15px !important;
  margin-bottom: 1rem !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
  background-color: var(--card-bg) !important;
}

.mobile-container .about-highlights {
  display: grid !important;
  grid-template-columns: 1fr !important;
  gap: 15px !important;
  margin-top: 1rem !important;
}

.mobile-container .highlight-item {
  display: flex !important;
  gap: 10px !important;
  align-items: flex-start !important;
}

.mobile-container .highlight-icon {
  font-size: 1.5rem !important;
  min-width: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mobile-container .highlight-text h4 {
  margin: 0 0 5px 0 !important;
  font-size: 1rem !important;
}

.mobile-container .highlight-text p {
  margin: 0 !important;
  font-size: 0.9rem !important;
}

/* Timeline fixes for mobile */
.mobile-container .timeline {
  margin-left: 0 !important;
  padding-left: 20px !important;
}

.mobile-container .timeline-item {
  margin-bottom: 2rem !important;
  position: relative !important;
}

.mobile-container .timeline-marker {
  position: absolute !important;
  left: -20px !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  background-color: var(--accent-color) !important;
  top: 0 !important;
}

.mobile-container .timeline-content {
  width: 100% !important;
}

.mobile-container .job-header {
  margin-bottom: 1rem !important;
}

.mobile-container .job-header h3 {
  font-size: 1.2rem !important;
  margin-bottom: 5px !important;
}

.mobile-container .job-meta {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  font-size: 0.9rem !important;
  color: var(--color-text-muted) !important;
}

.mobile-container .job-achievements {
  margin: 1rem 0 !important;
  padding-left: 20px !important;
}

.mobile-container .job-achievements li {
  margin-bottom: 0.8rem !important;
  font-size: 0.95rem !important;
}

.mobile-container .job-tech {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-top: 1rem !important;
}

.mobile-container .tech-tag {
  font-size: 0.8rem !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  background-color: rgba(var(--accent-color-rgb), 0.1) !important;
  color: var(--accent-color) !important;
}

/* Show all content on About page */
.mobile-container .about-content > div {
  display: block !important;
  width: 100% !important;
  max-width: 100% !important;
  overflow: visible !important;
}

/* Make all sections in About page visible */
.mobile-container .about-container section,
.mobile-container .about-container div {
  display: block !important;
  width: 100% !important;
  max-width: 100% !important;
  overflow: visible !important;
}

/* Talks Page Styling - Clean Implementation */
.mobile-container .talks-page {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 15px !important;
  margin: 0 auto !important;
  overflow-x: hidden !important;
  padding-bottom: 70px !important; /* Space for bottom nav */
}

.mobile-container .section-header {
  margin-bottom: 20px !important;
}

.mobile-container .section-title {
  font-size: 1.8rem !important;
  margin-bottom: 10px !important;
}

.mobile-container .section-subtitle {
  font-size: 1rem !important;
}

/* Fix for tag scrolling - key fix */
.mobile-container .talks-controls {
  margin: 0 -15px 20px -15px !important; /* Negative margin to counter the container padding */
  width: calc(100% + 30px) !important; /* Expand width to compensate for negative margins */
  padding: 0 !important;
  position: relative !important;
  overflow: visible !important;
}

.mobile-container .talks-filter::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.mobile-container .filter-button {
  white-space: nowrap !important;
  flex-shrink: 0 !important;
  padding: 8px 12px !important;
  border-radius: 20px !important;
  font-size: 0.85rem !important;
  background-color: var(--card-bg) !important;
  border: 1px solid var(--border-color) !important;
  color: var(--text-color) !important;
}

.mobile-container .filter-button.active {
  background-color: var(--accent-color) !important;
  color: white !important;
  border-color: var(--accent-color) !important;
}

.mobile-container .talks-component-title {
  font-size: 1.4rem !important;
  margin-bottom: 5px !important;
}

.mobile-container .view-toggle {
  display: flex !important;
  gap: 10px !important;
}

.mobile-container .view-button {
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  border: 1px solid var(--border-color) !important;
  background-color: transparent !important;
  color: var(--text-color) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mobile-container .view-button.active {
  background-color: var(--accent-color) !important;
  color: white !important;
  border-color: var(--accent-color) !important;
}

/* Talk cards container */
.mobile-container .talks-component-box {
  border-radius: 10px !important;
  overflow: hidden !important;
  background-color: var(--card-bg) !important;
  margin-bottom: 30px !important;
  width: 100% !important;
  padding: 0 !important;
}

.mobile-container .talks-component-header {
  padding: 15px !important;
  border-bottom: 1px solid var(--border-color) !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

.mobile-container .talks-component-content {
  padding: 15px !important;
}

.mobile-container .talks-grid {
  display: grid !important;
  grid-template-columns: 1fr !important;
  gap: 20px !important;
  width: 100% !important;
}

/* Talk cards */
.mobile-container .talk-card {
  width: 100% !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  background-color: var(--card-bg) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
}

.mobile-container .talk-card-content {
  padding: 15px !important;
}

.mobile-container .talk-card-actions {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

.mobile-container .watch-talk-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  background-color: var(--accent-color) !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  padding: 10px 20px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  text-decoration: none !important;
  width: 100% !important;
  max-width: 200px !important;
  text-align: center !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.mobile-container .watch-talk-button svg {
  width: 18px !important;
  height: 18px !important;
}

/* Fix iframe for talk videos */
.mobile-container iframe {
  max-width: 100% !important;
  border: none !important;
}

/* Override the aggressive selector for images */
.mobile-container img {
  max-width: 100% !important;
  aspect-ratio: attr(width) / attr(height);
  display: block;
  height: auto;
}

/* About hero section styles */
.mobile-container .about-hero {
  padding: 0 !important;
  border-radius: 12px !important;
  overflow: hidden !important;
  margin-bottom: 25px !important;
  width: 100% !important;
  background-color: #fff !important;
  box-shadow: 0 10px 20px rgba(0, 112, 243, 0.08) !important;
  border: 1px solid #f0f7ff !important;
  position: relative !important;
  display: block !important;
}

.mobile-container .about-hero::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(135deg, rgba(230, 240, 255, 0.7) 0%, rgba(255, 255, 255, 0) 50%) !important;
  z-index: 1 !important;
}

.mobile-container .about-hero-content {
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  z-index: 2 !important;
}

.mobile-container .about-hero-text {
  order: 2 !important;
  padding: 25px !important;
}

.mobile-container .about-hero-image {
  order: 1 !important;
  width: 100% !important;
  height: 300px !important;
  overflow: hidden !important;
}

.mobile-container .about-hero-image::after {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  pointer-events: none !important;
  z-index: 2 !important;
}

.mobile-container .about-hero-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  display: block !important;
  filter: brightness(1.02) !important;
}

.mobile-container .about-hero h1 {
  font-size: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
  color: #111 !important;
  position: relative !important;
  display: inline-block !important;
}

.mobile-container .about-hero h1::after {
  content: '' !important;
  position: absolute !important;
  bottom: -8px !important;
  left: 0 !important;
  width: 50px !important;
  height: 4px !important;
  background: linear-gradient(to right, var(--accent-color), var(--accent-color-secondary)) !important;
  border-radius: 2px !important;
}

.mobile-container .about-hero h2 {
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  margin-bottom: 15px !important;
  color: var(--accent-color) !important;
}

.mobile-container .about-tagline {
  font-size: 1rem !important;
  line-height: 1.6 !important;
  color: #555 !important;
  max-width: 100% !important;
  margin-bottom: 0 !important;
}

.mobile-container .about-social-links {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
}

.mobile-container .about-social-link {
  padding: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Add viewport hack to prevent iOS Safari layout shifts during scrolling */
@supports (-webkit-touch-callout: none) {
  .mobile-container {
    height: -webkit-fill-available;
  }
}

/* Optimize scrollable content for smoother performance */
.mobile-container .mobile-search-results,
.mobile-container .blog-cards-container,
.mobile-container .post-grid,
.mobile-container .talks-grid,
.mobile-container .timeline {
  /* Simplify performance optimizations */
  -webkit-overflow-scrolling: touch;
  backface-visibility: visible;
  transform: none;
  will-change: auto;
}

/* Remove problematic scrolling fixes that are breaking scrolling */
/* Prevent rubber-banding and scrolling issues on iOS */
body.mobile .mobile-container {
  /* Allow normal scrolling */
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Remove html.mobile-device fixed height that prevents scrolling */
html.mobile-device {
  height: auto;
  overflow: auto;
}

html.mobile-device body {
  height: auto;
  overflow: auto;
}

/* Reset the scrollable content transformations */
.mobile-container .mobile-search-results,
.mobile-container .blog-cards-container,
.mobile-container .post-grid,
.mobile-container .talks-grid,
.mobile-container .timeline {
  /* Just keep the basic touch scrolling */
  -webkit-overflow-scrolling: touch;
}

/* Disable the restrictive animation pausing during scroll */
.mobile-container.is-scrolling * {
  animation-play-state: running !important;
  transition: all 0.2s ease !important;
}

/* Remove hover effects that can cause jitters */
.mobile-container a:hover,
.mobile-container button:hover {
  transition: none !important;
}

/* Disable animations during scrolling for better performance */
.mobile-container.is-scrolling * {
  animation-play-state: paused !important;
  transition: none !important;
}

/* Improve click responsiveness */
.mobile-container a:active,
.mobile-container button:active {
  transform: scale(0.98);
  transition: transform 0.05s ease-out;
}

/* Fix iOS momentum scrolling for nested elements */
.mobile-container .scrollable-content {
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  overflow-y: auto;
}

/* Reduce motion for users who prefer it */
@media (prefers-reduced-motion: reduce) {
  .mobile-container * {
    animation: none !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

/* Optimize touch targets for better usability */
.mobile-container button,
.mobile-container a,
.mobile-container input[type="button"],
.mobile-container input[type="submit"] {
  min-height: 44px;
  min-width: 44px;
}

/* Fix iOS fixed position elements during scroll */
.mobile-container .bottom-nav,
.mobile-container .mobile-navbar {
  transform: none;
}

/* Prevent scrolling issues with fixed elements */
@supports (-webkit-touch-callout: none) {
  .mobile-container .bottom-nav {
    padding-bottom: env(safe-area-inset-bottom, 10px);
  }

  .mobile-container .mobile-navbar {
    padding-top: env(safe-area-inset-top, 10px);
  }
}

/* Add additional viewport height fix for iOS */
@supports (-webkit-touch-callout: none) {
  body.mobile,
  html.mobile-device {
    /* Fix iOS height issues */
    min-height: -webkit-fill-available;
    overflow-x: hidden;
  }

  /* Fix for iOS scroll position on page load */
  .mobile-container {
    min-height: -webkit-fill-available;
  }
}

/* iOS scroll position fix */
@media screen and (max-width: 767px) {
  html, body {
    overflow-x: hidden;
    height: auto;
    /* Force page to top */
    scroll-behavior: auto;
  }

  /* Force content to start at the top */
  .mobile-container .main-content {
    scroll-snap-type: y mandatory;
    scroll-padding-top: 0;
  }

  .mobile-container .main-content > div:first-child {
    scroll-snap-align: start;
    scroll-margin-top: 0;
  }
}

/* Active hash links fix */
.mobile-container [id] {
  scroll-margin-top: 0;
}

/* Mobile Post Tag Styling - Fix alignment and spacing */
.mobile-container .post-item .post-meta {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-bottom: 15px !important;
  align-items: center !important;
}

.mobile-container .post-item .post-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-top: 5px !important;
  width: 100% !important;
}

.mobile-container .post-item .post-tag {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  padding: 4px 10px !important;
  border-radius: 15px !important;
  font-size: 0.8rem !important;
  line-height: 1.2 !important;
  margin: 0 !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  height: 28px !important;
}

.mobile-container .post-item .post-tag a {
  text-decoration: none !important;
  color: inherit !important;
}

.mobile-container .post-item .post-tag-active {
  background-color: var(--accent-color) !important;
  color: white !important;
}

/* Blog Card Tag Styling for Mobile */
.mobile-container .blog-card-footer {
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 15px !important;
}

.mobile-container .blog-card-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  width: 100% !important;
}

.mobile-container .blog-card-tag {
  font-size: 0.8rem !important;
  padding: 4px 12px !important;
  border-radius: 15px !important;
  background-color: var(--tag-bg) !important;
  color: var(--text-muted) !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 28px !important;
  white-space: nowrap !important;
}

.mobile-container .blog-card-action {
  margin-left: 0 !important;
  width: 100% !important;
}

.mobile-container .blog-card-read-more {
  width: 100% !important;
  justify-content: center !important;
}

/* Tag Page Specific Styling for Mobile */
.mobile-container .tag-page {
  width: 100% !important;
  max-width: 100% !important;
  padding: 15px !important;
  margin: 0 auto !important;
  margin-bottom: 70px !important; /* Space for bottom nav */
  box-sizing: border-box !important;
  overflow-x: hidden !important;
}

.mobile-container .tag-page .tag-heading {
  font-size: 1.4rem !important;
  line-height: 1.3 !important;
  margin: 0 0 15px 0 !important;
  padding: 0 0 10px 0 !important;
  border-bottom: 1px solid var(--border-color) !important;
  color: var(--color-text) !important;
  font-weight: 600 !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  hyphens: auto !important;
  max-width: 100% !important;
}

/* Specifically prevent tag overflow */
.mobile-container .tag-page .post-item .post-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 6px !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 2px 0 8px 0 !important;
  padding: 0 !important;
}

.mobile-container .tag-page .post-item .post-tag {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.75rem !important;
  padding: 2px 8px !important;
  border-radius: 10px !important;
  height: auto !important;
  min-height: 22px !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: fit-content !important;
  margin: 0 !important;
}

/* Post Detail Page Tag Styling */
.mobile-container .post-detail .post-meta {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  margin-bottom: 20px !important;
  align-items: center !important;
}

.mobile-container .post-detail .post-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-top: 10px !important;
  width: 100% !important;
}

.mobile-container .post-detail .post-tag {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  padding: 5px 12px !important;
  border-radius: 20px !important;
  font-size: 0.85rem !important;
  line-height: 1.2 !important;
  margin: 0 !important;
  text-decoration: none !important;
  white-space: nowrap !important;
}

/* Fix for Related Posts section tags */
.mobile-container .related-posts .post-item .post-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 6px !important;
  margin-top: 5px !important;
}

.mobile-container .related-posts .post-tag {
  font-size: 0.75rem !important;
  padding: 3px 8px !important;
  height: 24px !important;
}

.mobile-container .blog-meta {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  margin-bottom: 20px !important;
  margin-top: 5px !important;
  width: 100% !important;
}

.mobile-container .blog-tag {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  padding: 5px 12px !important;
  border-radius: 20px !important;
  font-size: 0.85rem !important;
  line-height: 1.2 !important;
  margin: 0 5px !important;
  margin-top: 5px !important;
  text-decoration: none !important;
  white-space: nowrap !important;
}

/* Style for tags and links inside them */
.mobile-container a .blog-tag,
.mobile-container span.blog-tag,
.mobile-container .blog-tag a {
  text-decoration: none !important;
  color: var(--color-tag-text) !important;
}

/* Specific Post Detail Page styling */
.mobile-container .blog-header {
  margin-bottom: 30px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.mobile-container .blog-title {
  font-size: 1.8rem !important;
  line-height: 1.3 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

/* Fix spacing between date and tags */
.mobile-container .blog-meta {
  padding: 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  align-items: center !important;
}

/* Ensure link text inherits color */
.mobile-container .blog-tag a,
.mobile-container a .blog-tag {
  color: inherit !important;
  text-decoration: none !important;
}

/* Blog content paragraphs styling */
.mobile-container .blog-content p {
  margin-bottom: 1.2rem !important;
  line-height: 1.7 !important;
}

/* Ensure blog content code blocks don't overflow */
.mobile-container .blog-content pre,
.mobile-container .blog-content code {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  max-width: 100% !important;
  overflow-x: auto !important;
  padding: 1rem !important;
  border-radius: 6px !important;
}

/* Comprehensive tag styling for all tag variations in the blog */
.mobile-container .blog-tag,
.mobile-container .post-tag,
.mobile-container span[class*="tag"],
.mobile-container a[class*="tag"],
.mobile-container div[class*="tag"] {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  padding: 5px 12px !important;
  border-radius: 20px !important;
  font-size: 0.85rem !important;
  line-height: 1.2 !important;
  margin: 5px 5px 5px 0 !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  height: auto !important;
  min-height: 28px !important;
}

/* Ensure all tag links are properly styled */
.mobile-container a[href^="/tags/"],
.mobile-container a[href*="/tags/"] {
  text-decoration: none !important;
  color: inherit !important;
}

/* Fix tag containers */
.mobile-container div[class*="tags"],
.mobile-container .post-tags,
.mobile-container .blog-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-top: 10px !important;
  width: 100% !important;
  padding: 0 !important;
}

/* Fix mobile tag pages */
/* Target the exact container structure */
.mobile-container .main-content .mobile-content-wrapper {
  width: 100vw !important;
  max-width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden !important;
  box-sizing: border-box !important;
}

/* Adjust the tag page when displayed under the mobile container */
.mobile-container .content-wrapper .tag-page {
  width: 100% !important;
  max-width: 100% !important;
  padding: 15px !important;
  margin: 0 0 50px 0 !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
}

/* Fix the tag heading */
.mobile-container .content-wrapper .tag-page .tag-heading {
  font-size: 1.6rem !important;
  margin: 0 0 20px 0 !important;
  padding: 0 0 15px 0 !important;
  border-bottom: 1px solid var(--border-color) !important;
  line-height: 1.3 !important;
  font-weight: 600 !important;
  color: var(--color-text) !important;
  width: 100% !important;
  overflow-wrap: break-word !important;
}

/* Fix post list container */
.mobile-container .content-wrapper .tag-page .post-list {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Fix individual post items */
.mobile-container .content-wrapper .tag-page .post-item {
  width: 100% !important;
  max-width: 100% !important;
  padding: 12px !important;
  margin-bottom: 0 !important; /* Changed from 15px to work with the gap in post-list */
  border-radius: 8px !important;
  background-color: var(--card-bg) !important;
  border: 1px solid var(--border-color) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

/* Fix post title */
.mobile-container .content-wrapper .tag-page .post-item .post-title {
  font-size: 1.1rem !important;
  line-height: 1.3 !important;
  margin: 0 0 8px 0 !important;
  padding: 0 !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.mobile-container .content-wrapper .tag-page .post-item .post-title a {
  text-decoration: none !important;
  color: var(--color-text) !important;
}

/* Fix post metadata */
.mobile-container .content-wrapper .tag-page .post-item .post-meta {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 6px !important;
  margin-bottom: 8px !important;
  width: 100% !important;
}

/* Fix post date */
.mobile-container .content-wrapper .tag-page .post-item .post-date {
  font-size: 0.8rem !important;
  color: var(--color-text-muted) !important;
}

/* Fix post tags container */
.mobile-container .content-wrapper .tag-page .post-item .post-tags {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* Fix individual tag styling */
.mobile-container .content-wrapper .tag-page .post-item .post-tags .post-tag {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 5px 12px !important;
  border-radius: 20px !important;
  font-size: 0.85rem !important;
  background-color: var(--color-tag-bg) !important;
  color: var(--color-tag-text) !important;
  margin: 0 !important;
  white-space: nowrap !important;
  min-height: 28px !important;
}

/* Style the active tag */
.mobile-container .content-wrapper .tag-page .post-item .post-tags .post-tag-active {
  background-color: var(--accent-color) !important;
  color: white !important;
}

/* Fix post tag links */
.mobile-container .tag-page .post-item .post-tag a,
.mobile-container .post-tag a,
.mobile-container .blog-tag a,
.mobile-container a[href^="/tags/"] {
  color: inherit !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-tap-highlight-color: transparent !important;
  touch-action: manipulation !important;
}

/* Fix tag links to respond to touches without sticking */
.mobile-container .post-tag:active,
.mobile-container .blog-tag:active,
.mobile-container .tag-page .post-item .post-tag:active {
  opacity: 0.8 !important;
  transform: scale(0.95) !important;
  transition: all 0.1s ease-out !important;
}

/* Prevent scroll position issues when clicking tag links */
.mobile-container .tag-page {
  scroll-margin-top: 0px !important;
}

/* Fix entire post-item element to ensure proper post rendering */
.mobile-container .tag-page .post-list {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  max-width: 100% !important;
  gap: 15px !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Fix mobile tag rendering */
@media screen and (max-width: 767px) {
  /* Force scroll reset when navigating */
  html, body {
    scroll-behavior: auto !important;
  }

  /* Fix tag links */
  .post-tag a,
  .blog-tag a,
  a[href^="/tags/"] span {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: inherit !important;
    text-decoration: none !important;
    width: 100% !important;
    height: 100% !important;
  }

  /* Fix tag page container */
  .tag-page {
    width: 100% !important;
    padding: 15px !important;
    margin-bottom: 70px !important; /* Space for bottom nav */
    overflow-x: hidden !important;
  }

  /* Fix touch behavior */
  a[href^="/tags/"] {
    -webkit-tap-highlight-color: transparent !important;
    touch-action: manipulation !important;
  }

  /* Fix post tags display */
  .post-tags,
  .blog-tags,
  .tag-page .post-tags {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 8px !important;
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: visible !important;
  }

  /* Make tags more compact */
  .post-tag,
  .blog-tag,
  .tag-page .post-tag {
    font-size: 0.8rem !important;
    padding: 3px 8px !important;
    border-radius: 12px !important;
    line-height: 1.2 !important;
    height: auto !important;
    min-height: 24px !important;
    white-space: nowrap !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  /* Fix tag page heading */
  .tag-page .tag-heading {
    font-size: 1.4rem !important;
    line-height: 1.3 !important;
    margin: 0 0 15px 0 !important;
    padding: 0 0 10px 0 !important;
    border-bottom: 1px solid var(--border-color) !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    hyphens: auto !important;
  }

  /* Ensure tag container has appropriate width constraints */
  .mobile-container .tag-page {
    width: 100% !important;
    max-width: 100% !important;
    padding: 15px !important;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
  }
}

/* Force scroll position fixes */
.mobile-container {
  /* Basic scrolling enhancements without breaking normal behavior */
  -webkit-overflow-scrolling: touch;
  backface-visibility: visible;
  transform: none;
  will-change: auto;
}

/* Direct fixes for tag pages - override any other styles with !important flag and extreme specificity */
@media screen and (max-width: 767px) {
  /* Fix basic tag page container */
  .mobile-container .content-wrapper .tag-page,
  .mobile-container .tag-page,
  .tag-page {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    padding: 15px !important;
    margin: 0 auto !important;
    margin-bottom: 70px !important;
    overflow-x: hidden !important;
  }

  /* Fix post list */
  .mobile-container .content-wrapper .tag-page .post-list,
  .mobile-container .tag-page .post-list,
  .tag-page .post-list {
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Fix post items */
  .mobile-container .content-wrapper .tag-page .post-item,
  .mobile-container .tag-page .post-item,
  .tag-page .post-item {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    padding: 12px !important;
    margin: 0 !important;
    border-radius: 8px !important;
    border: 1px solid var(--border-color) !important;
    background-color: var(--card-bg) !important;
  }

  /* Fix post title */
  .tag-page .post-item .post-title {
    font-size: 1.1rem !important;
    line-height: 1.3 !important;
    margin: 0 0 8px 0 !important;
    overflow-wrap: break-word !important;
  }

  /* Fix post meta */
  .tag-page .post-item .post-meta {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    width: 100% !important;
    margin-bottom: 8px !important;
  }

  /* Fix post tags */
  .tag-page .post-item .post-tags {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 6px !important;
    width: 100% !important;
    margin: 5px 0 !important;
  }

  /* Fix individual tags */
  .tag-page .post-item .post-tags .post-tag {
    font-size: 0.75rem !important;
    padding: 3px 8px !important;
    border-radius: 12px !important;
    height: auto !important;
    min-height: 22px !important;
    display: inline-flex !important;
    align-items: center !important;
    white-space: nowrap !important;
  }

  /* Fix tag links */
  .tag-page .post-item .post-tags .post-tag a {
    color: inherit !important;
    text-decoration: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    height: 100% !important;
  }
}

/* Add viewport hack to prevent iOS Safari layout shifts during scrolling */
.viewport-fix {
  min-height: calc(100vh - 100px) !important;
  height: auto !important;
}

/* Hero section mobile customizations */
.mobile-container .hero-section {
  scroll-snap-align: start !important;
  flex-direction: column !important;
  margin: 0 auto 30px !important; /* Reduced from 40px */
  padding: 30px 0 20px !important; /* Reduced from 40px 0 */
  min-height: auto !important;
  overflow: visible !important;
}

.mobile-container .hero-content {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 20px !important;
  text-align: center !important;
  z-index: 2 !important;
}

.mobile-container .hero-decoration {
  max-width: 100% !important;
  margin: 10px 0 0 0 !important; /* Reduced from 20px */
  height: 180px !important; /* Reduced from 220px */
  overflow: visible !important;
  padding: 0 !important;
  animation: none !important; /* Disable float animation on mobile */
}

.mobile-container .hero-graphic {
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Shrink the SVG animation on mobile */
.mobile-container .hero-graphic svg {
  width: 250px !important; /* Reduced from 300px */
  height: 250px !important; /* Reduced from 300px */
  max-width: 100% !important;
  overflow: visible !important;
}

/* Reduce spacing after hero section */
.mobile-container .featured-section {
  margin-top: -30px !important; /* Increased negative margin to pull the section up */
}

/* Tech badges better mobile layout */
.mobile-container .tech-badges {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  margin-top: 20px !important;
}
